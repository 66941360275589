import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Router from "next/router";
import supabase from "../util/supabase.js";
import PuzzleWrapper from "../components/puzzle-wrapper.jsx";
import { getTwoferGooferState } from "../util/local-storage.js";
import { pickRandomTwofer } from "../util/puzzle-util.js";
import {  DEFAULT_PUZZLE } from "../util/puzzle.js";

import Layout from "../components/layout";

export default function Home({
  puzzles,
  experiments,
  currentDate,  
}) {
  const router = Router.useRouter();

  useEffect(() => {
    setRouterIsReady(router.isReady);
  }, [router.isReady]);

  // pid = puzzle id, use for picking specific puzzle
  // rid = random id, use to force a random puzzle
  // cid = current id, use to force the current puzzle
  const { pid, dbg, rid, cid } = router.query;
  const [routerIsReady, setRouterIsReady] = useState(false);
  const mostRecentPuzzleId = puzzles[puzzles.length - 1].id;
  let initialId;
  if (rid) {
    const fullState = getTwoferGooferState();
    initialId = pickRandomTwofer(puzzles, fullState, mostRecentPuzzleId, null);
  } else if (cid) {
    initialId = mostRecentPuzzleId;
  } else if (pid) {
    initialId = pid;
  } else {
    initialId = mostRecentPuzzleId;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const matchMobile = window.matchMedia("(max-width: 639px)");
    setIsMobile(matchMobile.matches);
    matchMobile.addEventListener("change", (e) => setIsMobile(e.matches));
  }, []);

  const [viewportHeight, setViewportHeight] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [scaleFactor, setScaleFactor] = useState(1);

  const puzzleContainerRef = useRef(null);

  useEffect(() => {
    const updateScaleFactor = () => {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      setViewportHeight(viewportHeight);
      setViewportWidth(viewportWidth);

      if (puzzleContainerRef.current) {
        const containerHeight = puzzleContainerRef.current.scrollHeight;
        const newScaleFactor = viewportHeight / containerHeight;

        // Only update the minScaleFactorRef if the newScaleFactor is lower
        setScaleFactor(newScaleFactor);
      }
    };

    const resizeObserver = new ResizeObserver(updateScaleFactor);

    if (puzzleContainerRef.current) {
      resizeObserver.observe(puzzleContainerRef.current);
      updateScaleFactor();
      window.addEventListener("resize", updateScaleFactor);
    }

    return () => {
      if (puzzleContainerRef.current) {
        resizeObserver.unobserve(puzzleContainerRef.current);
      }
      window.removeEventListener("resize", updateScaleFactor);
    };
  }, [puzzleContainerRef.current, viewportHeight]);

  return (
    <>
      <div
        className={`home-container relative grow flex w-full`}
        ref={puzzleContainerRef}
      >
        <div className="bg-white w-full dark:bg-gray-900">
          <div className="w-full sm:max-w-2xl sm:mx-auto px-2">
            {routerIsReady && (
              <PuzzleWrapper
                initialId={initialId}
                dbg={dbg}
                puzzles={puzzles}
                experiments={experiments}
                isMobile={isMobile}
                key={initialId}
                viewportHeight={viewportHeight}
                scaleFactor={scaleFactor}
                puzzleContainerRef={puzzleContainerRef}
                currentDate={currentDate}
              ></PuzzleWrapper>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

Home.getLayout = function getLayout(Home) {
  return (
    <Layout>
      {Home}
    </Layout>
  )
}

export async function getStaticProps() {
  const timeOptions = {
    timeZone: "America/New_York",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const formatter = new Intl.DateTimeFormat([], timeOptions);
  const currentDate = formatter.format(new Date());

  const { data, error } = await supabase
    .from("puzzles")
    .select()
    .lte("day", currentDate)
    .order("day", { ascending: true });

  if (error || !data) {
    return {
      props: {
        puzzles: [DEFAULT_PUZZLE],
        experiments: [],
      },
      revalidate: 60, // 1 minute
    };
  }

  const enabledExperimentsResp = await supabase
    .from("experiment_configs")
    .select()
    .eq("is_enabled", true);
  const enabledExperiments = (enabledExperimentsResp.data || []).map(
    (el) => el.experiment_name
  );

  return {
    props: {
      puzzles: data,
      experiments: enabledExperiments,
      currentDate: currentDate,
    },
    revalidate: 60, // 1 minute
  };
}
